// Define namespace
var VF = VF ? VF : {}; // eslint-disable-line no-use-before-define
VF.shopHost = 'shop.vodafone.de';
(function() {
    'use strict';
    /* Digital Simplicity 3 Config */
    this.globalconfig = {
        configname: 'Production Digital Simplicity 3 Config',
        error: 'Error',
        serverCodeNumber400: 'Bad request',
        serverCodeNumber401: 'Unauthorised access',
        serverCodeNumber403: 'Forbidden access',
        serverCodeNumber404: 'The requested resource is not available',
        serverCodeNumber405: 'Method not allowed',
        serverCodeNumber406: 'Not acceptable',
        serverCodeNumber409: 'A modification on a resource conflicted with the stored resource',
        serverCodeNumber413: 'Request entity too large',
        serverCodeNumber414: 'Request URI too large',
        serverCodeNumber415: 'Unsupported media type',
        serverCodeNumber422: 'Unprocessable Entity',
        serverCodeNumber500: 'Internal server error',
        serverCodeNumber501: 'The request cannot be completed due to lack of underlying functionality',
        serverCodeNumber503: 'Service unavailable',
        serverCodeNumber504: 'Bad gateway',
        wmapikey: 'ce399173-ebd0-29f8-8574-cd9515eebb7d',
        wmapiuri: '//eweb8.vfd2-testnet.de/api/help/',
        loglevel: 'warn',
        tealiumReportSuite: 'dev',
        searchBoxPrefix: '',
        swipeNotificationText: 'Swipe',
        devMode: true,
        noNextSolutionButton: 'Nein, n&auml;chste L&ouml;sung',
        noButton: 'Nein'
    };
}).call(this);
